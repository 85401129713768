<template>
  <div class="table_wrapper">
    <v-layout wrap v-if="1 == 2">
      <v-col cols="6" class="mx-auto">
        <BarChart :chartdata="chartData" />
      </v-col>
    </v-layout>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :search="search"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:[`item.c5`]="{ item }">
        <v-chip :color="'red'" small dark v-if="item.c5 == 'Buyer Account'">
          {{ item.c5 }}
        </v-chip>
        <span v-else>{{ item.c5 }}</span>
      </template>
      <template v-slot:[`item.c6`]="{ item }">
        <v-chip :color="'red'" small dark v-if="item.c6 == 'Buyer Account'">
          {{ item.c6 }}
        </v-chip>
        <span v-else>{{ item.c6 }}</span>
      </template>

      <template v-slot:[`item.c9`]="{ item }">
        <v-chip :color="'orange'" dark small>
          {{ item.c9 }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Statistics</v-toolbar-title>
          <v-btn
            small
            color="error"
            v-on:click="deleteSelected"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import BarChart from "../Components/BarChart.vue";

import moment from "moment";
export default {
  name: "Statistics",
  components: {
    BarChart,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [{ data: [] }],
      },
      selected: [],
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Name Surname", value: "c2", sortable: false },
        { text: "Email", value: "c3", sortable: false },
        { text: "Phone", value: "c4", sortable: false },
        { text: "Company Name", value: "c5", sortable: false },
        { text: "Store URL", value: "c6", sortable: false },
        { text: "Created at", value: "c7", sortable: false },
        { text: "Updated at", value: "c8", sortable: false },
        { text: "Moment", value: "c9", sortable: false },
        { text: "View", value: "c10", sortable: false },
        { text: "Store Name", value: "c11", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },

    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    deleteSelected: function() {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        axios
          .post("https://gowawe.com/api/admin/quotation/bulkdelete", {
            id: selectedMap,
            api_token: this.token,
          })
          .then((res) => {
            this.getRefreshData();
          })
          .catch((err) => {
            console.log(err);
            this.getRefreshData();
          });
      }
    },
    getRefreshData: function() {
      this.selected = [];
      this.getDataFromApi(this.activeTab);
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.id,
            c2: item.interaction_member.name,
            c3: item.interaction_member?.store?.email,
            c4: item.interaction_member.phone,
            c5: item.interaction_member?.store?.company_name || "Buyer Account",
            c6: item.interaction_member?.store?.store_url || "Buyer Account",
            c7: item.created_at,
            c8: item.updated_at,
            c9: this.parseDateTime(item.updated_at),
            c10: item.interaction_count,
            c11: item.exposed_member?.store.company_name,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      return axios
        .get(
          process.env.VUE_APP_API_URL +
            "get-all-contact-view?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token
        )
        .then(function(response) {
          return response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
